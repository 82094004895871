import React, { useEffect, useContext, useState } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import Img from 'gatsby-image'
import MAINLAYOUT from '../components/layouts/mainLayout.jsx'
import BOTTOM_MENU from '../components/bottom_menu/bottom_menu.jsx'
import { GlobalCategoriesContext } from '../context/GlobalContextProvider'
import { Auth } from 'aws-amplify'

const thankYou = () => {
  const { getAuthenticatedUser } = useContext(GlobalCategoriesContext)
  const [userData, setUserData] = useState(void 0)
  const { logoGray } = useStaticQuery(graphql`
    query {
      logoGray: file(relativePath: { eq: "img/logo_gray.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => {
    getAuthenticatedUser()
      .then(() => {
        Auth.currentUserInfo()
          .then(response => {
            setUserData(response)
          })
          .catch(err => {
            navigate('/')
          })
      })
      .catch(err => {
        navigate('/')
      })
  }, [])

  return (
    <MAINLAYOUT>
      <BOTTOM_MENU />
      <div className="pt-4 mx-auto grid grid-cols-1 max-w-bottomMenu">
        <div className="block pt-6 mx-auto mb-10" style={{ width: '250px' }}>
          <Img fluid={logoGray.childImageSharp.fluid} />
        </div>
        <div
          className="text-center text-teal leading-8"
          style={{ fontSize: '30px' }}
        >
          Thank you
          <br /> for posting!
        </div>
        <div
          className="mt-10 text-center leading-7"
          style={{ fontSize: '22px' }}
        >
          You post supports our
          <br /> community by helping
          <br /> members stay informed.
        </div>
      </div>
    </MAINLAYOUT>
  )
}

export default thankYou
