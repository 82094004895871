import React, { useContext } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import Img from 'gatsby-image'
import { GlobalDispatchContext } from '../../context/GlobalContextProvider'
export default () => {
  const dispatch = useContext(GlobalDispatchContext)
  const {
    gridView,
    uploadItem,
    mapView,
    postNowIcon,
    listViewIcon,
    mapViewIcon,
    profileIcon,
  } = useStaticQuery(graphql`
    query {
      gridView: file(relativePath: { eq: "img/store-02.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 36, height: 36) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      uploadItem: file(relativePath: { eq: "img/camera-02.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mapView: file(relativePath: { eq: "img/map-02.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 36, height: 36) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      postNowIcon: file(relativePath: { eq: "img/pop-up_icon.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 55, height: 64) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mapViewIcon: file(relativePath: { eq: "img/map-view_icon.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 55, height: 64) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      listViewIcon: file(relativePath: { eq: "img/list-view_icon.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 55, height: 64) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      profileIcon: file(relativePath: { eq: "img/profile_icon.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 36, height: 36) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div className="fixed bottom-0 left-0 right-0 z-40 pt-2 pb-1 mx-auto bg-teal rounded-t-md grid grid-cols-bottomMenu max-w-bottomMenu">
      <button
        className="mx-auto outline-none postNow focus:outline-none"
        type="button"
        onClick={() => {
          dispatch({ type: 'GRID_VIEW' })
          navigate('/search', { replace: false, state: { enableGrid: true } })
        }}
      >
        <Img
          className="menuIcon"
          fixed={gridView.childImageSharp.fixed}
          alt="crowdsupply"
        />
        <div className="tooltip-image">
          <Img fixed={listViewIcon.childImageSharp.fixed} alt="crowdsupply" />
        </div>
        <div className="buttonBottomText">Store List</div>
      </button>
      <button
        className="postNow mx-auto
                outline-none focus:outline-none"
        type="button"
        onClick={() => {
          navigate('/shareit')
        }}
      >
        <Img
          className="menuIcon"
          fixed={uploadItem.childImageSharp.fixed}
          alt="crowdsupply"
        />
        <div className="tooltip-image">
          <Img fixed={postNowIcon.childImageSharp.fixed} alt="crowdsupply" />
        </div>
        <div className="buttonBottomText">Post Now</div>
      </button>
      <button
        className="postNow mx-auto
                outline-none focus:outline-none"
        type="button"
        onClick={() => {
          dispatch({ type: 'MAP_VIEW' })
          navigate('/search', { replace: true, state: { enableGrid: false } })
        }}
      >
        <Img
          className="menuIcon"
          fixed={mapView.childImageSharp.fixed}
          alt="crowdsupply"
        />

        <div className="buttonBottomText">Map</div>
      </button>
      <button
        className="postNow mx-auto
                outline-none focus:outline-none"
        type="button"
        onClick={() => {
          dispatch({ type: 'MAP_VIEW' })
          navigate('/profile', { replace: true, state: { enableGrid: false } })
        }}
      >
        <Img
          className="menuIcon"
          fixed={profileIcon.childImageSharp.fixed}
          alt="crowdsupply"
        />
        <div className="buttonBottomText">Profile</div>
      </button>
    </div>
  )
}
